 <template>
   <div class="l-form  sp-pb-4">

     <div class="l-form__item">
       <div class="h4  sp-mb-2">{{ $t("on_boarding.owner_fields.title") }}</div>
       <p>{{ $t("on_boarding.owner_fields.intro") }}</p>
       <hr/>
     </div>


     <div class="l-form__item h5  sp-mb-0">{{ $t("on_boarding.owner_fields.business_info") }}</div>
     <template v-if="firstTime">

       <div class="l-form__item">
         <label class="form-label" for="legalName">{{ $t('form_fields.company_name') }}</label>
         <d-input id="legalName" v-model="formData.legalName" :class="{ 'is-invalid': hasError('legalName') }" />
         <d-form-invalid-feedback v-if="hasError('legalName')" :force-show="true">
           <span v-html="getError('legalName')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item">
         <label class="form-label" for="locale">{{ $t('form_fields.language') }}</label>
         <d-select id="locale" v-model="formData.locale" :class="{ 'is-invalid': hasError('locale') }">
           <option value="" disabled>{{ $t('form_fields.choose_a_language') }}</option>
           <template v-for="(item, index) in language">
             <option :value="item.value" :key="index">
               {{ item.text }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('locale')" :force-show="true">
           <span v-html="getError('locale')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item">
         <label class="form-label" for="email">{{ $t('form_fields.email') }}</label>
         <d-input id="email" v-model="formData.email" type="email" :class="{ 'is-invalid': hasError('email') }" />
         <d-form-invalid-feedback v-if="hasError('email')" :force-show="true">
           <span v-html="getError('email')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item">
         <label class="form-label" for="country">{{ $t('form_fields.country') }}</label>
         <d-select id="country" v-model="formData.country" :class="{ 'is-invalid': hasError('country') }">
           <option value="" disabled>{{ $t('form_fields.choose_a_country') }}</option>
           <template v-for="(item, index) in businessCountries">
             <option :value="item.value" :key="index">
               {{ item.text }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('country')" :force-show="true">
           <span v-html="getError('country')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="currency">{{ $t('form_fields.currency') }}</label>
         <d-select id="currency" v-model="formData.currency" :class="{ 'is-invalid': hasError('currency') }">
           <option value="" disabled>{{ $t('form_fields.choose_a_currency') }}</option>
           <template v-for="(item, index) in currencies">
             <option :value="item.value" :key="index">
               {{ item.text }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('currency')" :force-show="true">
           <span v-html="getError('currency')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="legalEntity">{{ $t('form_fields.legal_entity') }}</label>
         <d-select id="legalEntity" v-model="formData.legalEntity" :class="{ 'is-invalid': hasError('legalEntity') }">
           <option value="" disabled>{{ $t('form_fields.choose_a_legal_entity') }}</option>
           <template v-for="(item, index) in legalEntityList">
             <option :value="item.legalEntity" :key="index">
               {{ item.legalEntity }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('legalEntity')" :force-show="true">
           <span v-html="getError('legalEntity')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="cocID">{{ $t('form_fields.coc') }}</label>
         <d-input id="cocID" v-model="formData.cocID" :class="{ 'is-invalid': hasError('cocID') }" />
         <d-form-invalid-feedback v-if="hasError('cocID')" :force-show="true">
           <span v-html="getError('cocID')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="vatID">{{ $t('form_fields.vat') }}</label>
         <d-input id="vatID" v-model="formData.vatID" :class="{ 'is-invalid': hasError('vatID') }" />
         <d-form-invalid-feedback v-if="hasError('vatID')" :force-show="true">
           <span v-html="getError('vatID')"></span>
         </d-form-invalid-feedback>
       </div>
     </template>
     <div v-else class="l-form__item">
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.company_name') }}:</strong>{{ formData.legalName }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.language') }}:</strong>{{ language.find(l => l.value === formData.locale ).text }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.email') }}:</strong>{{ formData.email }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.country') }}:</strong>{{ businessCountries.find(cp => cp.value === formData.country ).text }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.currency') }}:</strong>{{ currencies.find(c => c.value === formData.currency ).text }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.legal_entity') }}:</strong>{{ formData.legalEntity }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.coc') }}:</strong>{{ formData.cocID }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.vat') }}:</strong>{{ formData.vatID }}
       </div>

     </div>


     <div class="l-form__item h5  sp-mt-3  sp-mb-0">{{ $t("on_boarding.owner_fields.address_info") }}</div>
     <template v-if="firstTime">
       <div class="l-form__item">
         <label class="form-label" for="address">{{ $t('form_fields.address') }}</label>
         <d-input id="address" v-model="formData.address" :class="{ 'is-invalid': hasError('address') }" />
         <d-form-invalid-feedback v-if="hasError('address')" :force-show="true">
           <span v-html="getError('address')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="zipcode">{{ $t('form_fields.zipcode') }}</label>
         <d-input id="zipcode" v-model="formData.zipcode" :class="{ 'is-invalid': hasError('zipcode') }" />
         <d-form-invalid-feedback v-if="hasError('zipcode')" :force-show="true">
           <span v-html="getError('zipcode')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="city">{{ $t('form_fields.city') }}</label>
         <d-input id="city" v-model="formData.city" :class="{ 'is-invalid': hasError('city') }" />
         <d-form-invalid-feedback v-if="hasError('city')" :force-show="true">
           <span v-html="getError('city')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item">
         <div class="form-label">{{ $t('form_fields.country') }}</div>
         <div>{{ businessCountries.find(c => c.value === formData.country).text }}</div>
       </div>

     </template>
     <div v-else class="l-form__item">
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.address') }}:</strong>{{ formData.address }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.zipcode') }}:</strong>{{ formData.zipcode }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.city') }}:</strong>{{ formData.city }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.country') }}:</strong>{{ businessCountries.find(cp => cp.value === formData.country ).text }}
       </div>
     </div>

     <div class="l-form__item h5  sp-mt-3  sp-mb-0">{{ $t("on_boarding.owner_fields.business_owner") }}</div>
     <template v-if="firstTime">

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="gender">{{ $t('form_fields.gender.label') }}</label>
         <d-select id="gender" v-model="formData.owner.gender" :class="{ 'is-invalid': hasError('owner_gender') }">
           <template v-for="(option, index) in genders">
             <option :value="option.value" :key="index" :disabled="option.value == ''">
               {{ $t('form_fields.gender.options.' + option.key ) }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('owner_gender')" :force-show="true">
           <span v-html="getError('owner_gender')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label">{{ $t('form_fields.birthdate') }}</label>
         <date-picker v-model="formData.owner.birthdate"
                      :settings="{
                        maxDate: new Date().setFullYear((new Date().getFullYear() - 18))
                      }"
         ></date-picker>

         <d-form-invalid-feedback v-if="hasError('owner_birthdate')" :force-show="true">
           <span v-html="getError('owner_birthdate')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="firstName">{{ $t('form_fields.firstname') }}</label>
         <d-input id="firstName" v-model="formData.owner.firstName" :class="{ 'is-invalid': hasError('owner_firstName') }" />
         <d-form-invalid-feedback v-if="hasError('owner_firstName')" :force-show="true">
           <span v-html="getError('owner_firstName')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="lastName">{{ $t('form_fields.lastname') }}</label>
         <d-input id="lastName" v-model="formData.owner.lastName" :class="{ 'is-invalid': hasError('owner_lastName') }" />
         <d-form-invalid-feedback v-if="hasError('owner_lastName')" :force-show="true">
           <span v-html="getError('owner_lastName')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item">
         <label class="form-label" for="ownerEmail">{{ $t('form_fields.email') }}</label>
         <d-input id="ownerEmail" v-model="formData.owner.email" type="email" :class="{ 'is-invalid': hasError('owner_email') }" />
         <d-form-invalid-feedback v-if="hasError('owner_email')" :force-show="true">
           <span v-html="getError('owner_email')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item">
         <label class="form-label" for="ownerEmailConfirmation">{{ $t('form_fields.email_confirmation') }}</label>
         <d-input id="ownerEmailConfirmation" v-model="emailConfirmation" type="email" :class="{ 'is-invalid': hasError('owner_email_confirmation') }" />
         <d-form-invalid-feedback v-if="hasError('owner_email_confirmation')" :force-show="true">
           <span v-html="getError('owner_email_confirmation')"></span>
         </d-form-invalid-feedback>
       </div>

     </template>
     <div v-else class="l-form__item">
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.gender.label') }}:</strong>{{ $t('form_fields.gender.options.' + genders.find(l => l.value === formData.owner.gender ).key )}}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.birthdate') }}:</strong>{{ formData.owner.birthdate }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.firstname') }}:</strong>{{ formData.owner.firstName }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.lastname') }}:</strong>{{ formData.owner.lastName }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.email') }}:</strong>{{ formData.owner.email }}
       </div>
     </div>

     <div class="l-form__item h5  sp-mt-3  sp-mb-0">{{ $t("on_boarding.owner_fields.administrative_contact") }}</div>
     <template v-if="firstTime">
       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="gender">{{ $t('form_fields.gender.label') }}</label>
         <d-select id="gender" v-model="formData.administrativeContact.gender" :class="{ 'is-invalid': hasError('administrativeContact_gender') }">
           <template v-for="(option, index) in genders">
             <option :value="option.value" :key="index" :disabled="option.value == ''">
               {{ $t('form_fields.gender.options.' + option.key ) }}
             </option>
           </template>
         </d-select>
         <d-form-invalid-feedback v-if="hasError('administrativeContact_gender')" :force-show="true">
           <span v-html="getError('administrativeContact_gender')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label">{{ $t('form_fields.birthdate') }}</label>
         <date-picker v-model="formData.administrativeContact.birthdate"
                      :settings="{
                        maxDate: new Date().setFullYear((new Date().getFullYear() - 18))
                      }"
         ></date-picker>

         <d-form-invalid-feedback v-if="hasError('administrativeContact_birthdate')" :force-show="true">
           <span v-html="getError('administrativeContact_birthdate')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="firstName">{{ $t('form_fields.firstname') }}</label>
         <d-input id="firstName" v-model="formData.administrativeContact.firstName" :class="{ 'is-invalid': hasError('administrativeContact_firstName') }" />
         <d-form-invalid-feedback v-if="hasError('administrativeContact_firstName')" :force-show="true">
           <span v-html="getError('administrativeContact_firstName')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="lastName">{{ $t('form_fields.lastname') }}</label>
         <d-input id="lastName" v-model="formData.administrativeContact.lastName" :class="{ 'is-invalid': hasError('administrativeContact_lastName') }" />
         <d-form-invalid-feedback v-if="hasError('administrativeContact_lastName')" :force-show="true">
           <span v-html="getError('administrativeContact_lastName')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item">
         <label class="form-label" for="ownerEmail">{{ $t('form_fields.email') }}</label>
         <d-input id="ownerEmail" v-model="formData.administrativeContact.email" type="email" :class="{ 'is-invalid': hasError('administrativeContact_email') }" />
         <d-form-invalid-feedback v-if="hasError('administrativeContact_email')" :force-show="true">
           <span v-html="getError('administrativeContact_email')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item  sp-mt-3">
         <d-checkbox inline v-model="hipayAgreed">{{ $t('form_fields.agreement') }}</d-checkbox>
         <d-form-invalid-feedback v-if="hasError('hipayAgreed')" :force-show="true">
           <span v-html="getError('hipayAgreed')"></span>
         </d-form-invalid-feedback>
       </div>


       <div class="l-form__item" data-size="1/2">
         <label class="form-label" for="captachaMessage">{{ $t('form_fields.captcha') }}</label>
         <d-input id="captachaMessage" v-model="captachaMessage" :class="{ 'is-invalid': hasError('captachaMessage') }" />
         <d-form-invalid-feedback v-if="hasError('captachaMessage')" :force-show="true">
           <span v-html="getError('captachaMessage')"></span>
         </d-form-invalid-feedback>
       </div>

       <div class="l-form__item" data-size="1/2">
         <img :src="captcha.captachaURL" alt="recaptcha" width="200" height="50"/>
       </div>

     </template>
     <div class="l-form__item" v-else>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.gender.label') }}:</strong>{{ $t('form_fields.gender.options.' + genders.find(l => l.value === formData.administrativeContact.gender ).key )}}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.birthdate') }}:</strong>{{ formData.administrativeContact.birthdate }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.firstname') }}:</strong>{{ formData.administrativeContact.firstName }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.lastname') }}:</strong>{{ formData.administrativeContact.lastName }}
       </div>
       <div>
         <strong style="display: inline-block; width: 240px;">{{ $t('form_fields.email') }}:</strong>{{ formData.administrativeContact.email }}
       </div>
     </div>

     <div v-if="hasError('others')" class="l-form__item">
       <d-form-invalid-feedback  :force-show="true">
         <span v-html="getError('others')"></span>
       </d-form-invalid-feedback>
     </div>

     <div class="l-form__item  u-divide">

       <router-link v-if="!firstTime" :to="{name: 'onBoarding.initial', params: {providerId: providerId}}" class="btn  btn-light">
         {{ $t('form_fields.to_overview') }}
       </router-link>
       <div v-else></div>

       <loading v-if="isLoading" />
       <d-button v-else-if="firstTime" @click="submit">{{ $t('form_fields.save_continue') }}</d-button>
     </div>

   </div>
</template>

<script>

import {mapProviderFields, mapFileFields, mapRegistrationFields} from "~/store";
import mixins from '~/mixins';
import formSettings, {rules, legalEntity as legalEntityMapping} from "~/onBoardingSettings"
import DatePicker from "@/components/DatePicker";
import Loading from "@/components/loading";

export default {
  name: "OwnerFields",
  components: {Loading, DatePicker},
  mixins: [mixins],

  props: {
    providerId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      errors: [],
      isLoading: false,
      captcha: {},
      captachaMessage: "",
      businessCountries: formSettings.businessCountries,
      emailConfirmation: "",
      isSoloTrader: false,

      rules: {

        address: rules.stringCheck.rules,
        zipcode: rules.stringCheck.rules,
        city: rules.stringCheck.rules,
        country: rules.country.rules,

        legalName: rules.stringCheck.rules,
        locale: rules.locale.rules,

        email: rules.email.rules,
        currency: rules.currency.rules,

        // businessType: rules.stringCheck.rules,
        legalEntity: rules.stringCheck.rules,
        cocID: rules.stringCheck.rules,
        vatID: rules.stringCheck.rules,

        owner: {
          gender: rules.gender.rules,
          firstName: rules.stringCheck.rules,
          lastName: rules.stringCheck.rules,
          birthdate: rules.date.rules,
          email: rules.email.rules,
        },

        administrativeContact: {
          gender: rules.gender.rules,
          firstName: rules.stringCheck.rules,
          lastName: rules.stringCheck.rules,
          birthdate: rules.date.rules,
          email: rules.email.rules,
        },

        hipayAgreed: rules.hipayAgreed.rules,
        captachaMessage: rules.stringCheck.rules,
      },

      genders: formSettings.genders,

      language: [
        {text: this.$t('form_language.english'), value: "en_GB"},
        {text: this.$t('form_language.dutch'), value: "nl_NL"},
        {text: this.$t('form_language.german'), value: "de_DE"},
        {text: this.$t('form_language.spanish'), value: "es_ES"},
        {text: this.$t('form_language.french'), value: "fr_FR"},
        {text: this.$t('form_language.italian'), value: "it_IT"},
        {text: this.$t('form_language.portugese'), value: "pt_PT"},
      ],

      currencies: [
        {text: this.$t('form_currency.euro'), value: 'EUR'},
        {text: this.$t('form_currency.us-dollar'), value: 'USD'},
        {text: this.$t('form_currency.swiss-franc'), value: 'CHF'},
        {text: this.$t('form_currency.pound-sterling'), value: 'GBP'}
      ]
    }
  },

  computed: {
    ...mapProviderFields([
      'formData',
      'hipayAgreed',
    ]),
    ...mapFileFields([
      'docID',
      'docCOC',
      'docAOA',
      'documentsToValidate',
      'validatedDocuments'
    ]),
    ...mapRegistrationFields([
      'firstTime',
    ]),

    legalEntityList() {

      if(this.formData.country === '') return [];
      if(legalEntityMapping[this.formData.country] === undefined) {

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.errors.push({
          field: 'others',
          messages: [`Country ${this.formData.country} has no legal entity mapping`]
        });

        return [];
      }

      return legalEntityMapping[this.formData.country];
    },

    pickerLocale() {
      let customLocale = this.formData.locale
      return customLocale.replace("_", "-")
    }
  },

  mounted() {
    this.getCaptcha()
  },

  methods: {

    hasError: function (field) {
      return this.errors.find(error => error.field === field);
    },

    getError: function (field) {
      return this.errors.find(error => error.field === field).messages.join('<br/>');
    },

    getCaptcha() {
      this.$apollo.query({
        query: require('~/graphql/onBoarding/GetHipayCaptcha.gql'), fetchPolicy: 'no-cache'
      })
      .then(data => {
        this.captcha = data.data.hiPayCaptcha

        let captchaUrl = data.data.hiPayCaptcha.captcha_img
        const startIndex = captchaUrl.indexOf("data")
        const endIndex = captchaUrl.indexOf("' alt=")

        this.captcha.captachaURL = captchaUrl.substring(startIndex, endIndex)
      })
      .catch((error) => {
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });
      })
    },

    updateProvider() {

      this.isLoading = true
      let variables = {
        id: this.providerId,
        input: {
          owner: this.formData.owner,
          administrativeContact: this.formData.administrativeContact,
          legalName: this.formData.legalName,
          locale: this.formData.locale,
          email: this.formData.email,
          legalEntity: this.formData.legalEntity,
          legalEntitySolo: this.isSoloTrader,
          businessType: this.formData.businessType,
          cocID: this.formData.cocID,
          vatID: this.formData.vatID,
          currency: this.formData.currency,
          location: {
            address: {
              street: this.formData.address,
              city: this.formData.city,
              zipCode: this.formData.zipcode,
              country: this.formData.country
            }
          }
        }
      }

      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/UpdateProvider.gql'), variables, fetchPolicy: 'no-cache'
      }).then(data => {

        new Promise((resolve, reject) => {
          if(data.data.updateProvider.success) resolve();
          else reject(data.data.updateProvider);
        })
        .then(this.createHipayAccount)
        .catch((error) => {

          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });
        })

      }).catch(error => {
        console.warn(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });

      }).then(() => {
          this.isLoading = false
        })
    },

    createHipayAccount() {

      this.isLoading = true

      // variables with captcha id and captcha message
      let variables = {
        providerID: this.providerId,
        captcha: {
          id: this.captcha.captcha_id,
          phrase: this.captachaMessage
        }
      }

      this.$apollo.mutate({
        mutation: require('~/graphql/onBoarding/CreateHiPayAccount.gql'), variables, fetchPolicy: 'no-cache'
      })
      .then(data => {

        new Promise((resolve, reject) => {
          if(data.data.createHiPayAccount.success) resolve();
          else reject(data.data.createHiPayAccount.errors);
        })
        .then(
          this.$router.push({name: 'onBoarding.step2', params: {providerId: this.providerId}})
        )
        .catch((error) => {

          console.error(error)
          this.errors.push({
            field: 'others',
            messages: [error.message]
          });

          this.captachaMessage = ""
          this.captcha = {}
          this.getCaptcha()
        })
      })
      .catch((error) => {
        console.error(error)
        this.errors.push({
          field: 'others',
          messages: [this.removeDefaultGraphQLPrefix(error.message)]
        });

        this.captachaMessage = ""
        this.captcha = {}
        this.getCaptcha()
      }).then(() => {
        this.isLoading = false
      })
    },

    submit() {
      this.errors = [];
      let valid = true;

      for( let attribute in this.rules) {

        if(attribute === 'owner') {
          for( let subAttribute in this.rules.owner) {
            const rulesValid = this.runValidation(this.formData.owner[subAttribute], this.rules.owner[subAttribute]);
            if(!rulesValid[0]) {
              valid = false;
              this.errors.push({
                field: 'owner_' + subAttribute,
                messages: rulesValid[1]
              });
            }
          }
        }
        else if(attribute === 'administrativeContact') {
          for( let subAttribute in this.rules.administrativeContact) {
            const rulesValid = this.runValidation(this.formData.administrativeContact[subAttribute], this.rules.administrativeContact[subAttribute]);
            if(!rulesValid[0]) {
              valid = false;
              this.errors.push({
                field: 'administrativeContact_' + subAttribute,
                messages: rulesValid[1]
              });
            }
          }
        }
        else {

          let rulesValid;

          if(this.in_array(attribute, ['hipayAgreed', 'captachaMessage'])) rulesValid = this.runValidation(this[attribute], this.rules[attribute]);
          else rulesValid = this.runValidation(this.formData[attribute], this.rules[attribute]);

          if(!rulesValid[0]) {
            valid = false;
            this.errors.push({
              field: attribute,
              messages: rulesValid[1]
            });
          }
        }
      }

      // Confirm that the owner email and confirmation are the same
      if(this.formData.owner.email !== this.emailConfirmation) {
        valid = false
        this.errors.push({
          field: 'owner_email_confirmation',
          messages: [this.$t('form_validation.email_confirmation_invalid')]
        });
      }

      // If valid form submit all data to the server
      if (!valid) {
        this.errors.push({
          field: 'others',
          messages: [this.$t('form_validation.contains_errors')]
        });
        return
      }

      const legalEntity = this.legalEntityList.find(option => option.legalEntity === this.formData.legalEntity)
      this.formData.businessType = legalEntity.hipayEntity
      this.isSoloTrader = this.legalEntityList.find(option => option.legalEntity === this.formData.legalEntity).legalEntitySolo
      this.updateProvider()
    },
  }
};
</script>
